div#RecipesList {
  .p-column-filter {
    width: 100%
  }

  .thumb {
    width: 48px;
    height: 48px;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
}
