div#sku_items {
  input.p-column-filter {
    width: 100%;
  }

  div.tables-row {
    height: 85vh;
  }

  .form-dropdown {
    height: calc(1.8em + 0.75rem + 2px);
  }
}
