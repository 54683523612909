div#Dashboard {
  display: flex;

  .sidebar-box {
    box-sizing: inherit;
  }

  .sidebar-container {
    flex: 0 0 auto;
    height: 100vh;
  }

  .sidebar {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    z-index: 1001;
    -webkit-overflow-scrolling: touch;
    // temporary style
    position: fixed;
    top: 0;
    outline: 0;
    color:white;
  }

  .sidebar-left {
    left: 0;
    right: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .main-content {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    padding: 8px 20px;
  }

  .sidebar-content {
    width: 210px;
    position: relative;
    overflow-x: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    padding-top: 0;
    white-space: nowrap;
    background-color:mediumseagreen;

    .sidebar-title{
      color:white;
    }

    .logo {
      width: 4rem;
      background-color: white;
      margin: 2rem;
      padding: 0.8rem;
      border-radius: 30%;
    }

    .nav-item {
      align-items: center;
      vertical-align: middle;
      padding: 10px 20px;
      cursor: pointer;
      white-space: initial;

      &:hover, &:hover .sidebar-title {
        background: #e9ecef;
        color:mediumseagreen;
      }

      .nav-icon {
        color: #64DE9C !important;
      }
    }

    .copyright {
      font-size: 0.875rem;
      margin-left: auto;
      margin-right: auto;
      background: #ffffff57;
      width: 100%;
      text-align: center!important;
    }
  }
}
