.App {
  .text-halen {
    color: #64DE9C !important;
    font-size: 1.5rem;
    font-weight: 300;
  }

  .text-halen-bold{
    font-weight:bold;
  }
}
