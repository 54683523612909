div#Users {
  .p-column-filter {
    width: 100%
  }

  .status-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-initial {
      background: #FEEDAF;
      color: #8A5340;
    }

    &.status-confirmed {
      background-color: #FFD8B2;
      color: #805B36;
    }

    &.status-free_period {
      background-color: #C8E6C9;
      color: #256029;
    }

    &.status-free_expired {
      background-color: #FFCDD2;
      color: #C63737;
    }

    &.status-paid {
      background-color: #ECCFFF;
      color: #694382;
    }
  }
}
