div#EditRecipe {
  .p-row-editor-init {
    margin-right: 0.5rem;
  }

  .form-dropdown {
    height: calc(1.8em + 0.75rem + 2px);
  }

  .p-fileupload-choose {
    background: #FBC02D;
    border: 1px solid #FBC02D;
    border-radius: 2rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .recipe-image {
      display: block;
      max-width:512px;
      max-height:256px;
      width: auto;
      height: auto;
  }

  .switch-label {
    height: 100%;
    vertical-align: middle;
    margin-right: 10px;
  }

  .p-inputswitch {
    vertical-align: middle;
  }

  .card-header{
    background: mediumseagreen !important;
  }

  .card-header h3{
      color:white;
  }
}
