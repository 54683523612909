div#Profile {
  .header {
    min-height: 300px;
//    background-image: url(../assets/img/theme/comida.jpeg);
    background-size: cover;
    background-position: center top;
  }

  .btn {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.625rem 1.25rem;
  }

  .btn-primary {
    color: #fff;
    background-color: #64DE9C;
    border-color: #64DE9C;
    box-shadow: 0 4px 4px rgba(100, 222, 156, 0.4);
    border-radius: 50px;

  }

  .btn-primary:hover {
    color: #fff;
    background-color: #64DE9C;
    border-color: #64DE9C;
    box-shadow: 0 4px 4px rgba(100, 222, 156, 0.8);
    border-radius: 50px;

  }

  .btn-primary:focus,
  .btn-primary.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5);
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #324cdd;
    border-color: #5e72e4;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
  }

  .btn-primary-add {
    color: #fff;
    background-color: #FFA860;
    border-color: #FFA860;
    /*box-shadow: 0px 4px 4px rgba(100, 222, 156, 0.4);*/
    border-radius: 50px;

  }

  .btn-primary-add:hover {
    color: #fff;
    background-color: #FFA860;
    border-color: #FFA860;
    /*box-shadow: 0px 4px 4px rgba(100, 222, 156, 0.8);*/
    border-radius: 50px;

  }

  /*.btn-primary-add:focus,
  .btn-primary-add.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5);
  }*/

  .btn-primary-add.disabled,
  .btn-primary-add:disabled {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4;
  }

  .btn-primary-add:not(:disabled):not(.disabled):active,
  .btn-primary-add:not(:disabled):not(.disabled).active,
  .show>.btn-primary-add.dropdown-toggle {
    color: #fff;
    background-color: #324cdd;
    border-color: #5e72e4;
  }

  .btn-primary-add:not(:disabled):not(.disabled):active:focus,
  .btn-primary-add:not(:disabled):not(.disabled).active:focus,
  .show>.btn-primary-add.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
  }

  .profile-avatar {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    transform: translate(-50%, -30%);
    position: absolute;
    left: 50%;
    transition: all 0.2s ease-in-out;
    border-style: inset;
    border-color: #64DE9C !important;
    border-width: 4px;
    bottom: -9rem;
  }

  i.profile-avatar {
    font-size: 64px;
  }

  .card-header{
    background: mediumseagreen!important;
  }

  .card-header h3{
    color:white;
  }
}
